<template>
    <div class="spec-client" v-loading="loading">
        <div class="order-title">
            <p>מספר לקוח</p>
        </div>
        <el-input v-model="makat_string" placeholder="הכנס מספר לקוח" />
        <el-button type="success" @click="load_client">טען לקוח</el-button>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { slide_pop_error, slide_pop_successs } from '../../../Methods/Msgs';
import { projectFunctions } from '../../../firebase/config';
export default {
setup(){
    const loading = ref(false);
    const makat_string = ref('');

    const load_client = async () => {
        if(!makat_string.value || makat_string.value.trim().length != 4){
            slide_pop_error("אנא הכנס מספר לקוח תקני, בן 4 ספרות בלבד!")
        }else{
            loading.value = true
            slide_pop_successs("טוען לקוח, אנא המתן!")
            var res = await projectFunctions.httpsCallable("update_clients_solo")({makat: makat_string.value})
            if(res){
                slide_pop_successs("הלקוח נטען בהצלחה!")
            }else{
                slide_pop_error("שגיאה, אנא בדוק את מספר הלקוח")
            }
            loading.value = false;
            makat_string.value = '';
        }
    }

    return{
        loading, makat_string, load_client
    }
}
}
</script>

<style scoped>
.spec-client{
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    padding: 5px;
}
.order-title{
    flex-shrink: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(235, 235, 235);
    font-size: 24px;
    margin-bottom: 5px;
}
</style>