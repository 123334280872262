<template>
    <div class="spec-product" v-loading="loading">
        <div class="title">
            <p>הכנס מקטים מופרדים בפסיק</p>
        </div>
        <div class="input-makats">
            <el-input v-model="makats_string" placeholder="הכנס מקטים כאן" />
        </div>
        <el-button type="success" @click="check_makats">טען מקטים</el-button>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { slide_pop_error, slide_pop_successs } from '../../../Methods/Msgs';
import { projectFunctions } from '../../../firebase/config';
export default {
setup(){
    const loading = ref(false);

    const makats_string = ref('');
    
    const check_makats = async () => {
        loading.value = true
        var makats = makats_string.value.split(',')
        if(makats.length > 0){
            for(let i =0; i< makats.length; i++){
                if(makats[i].length == 4){
                    let op = await projectFunctions.httpsCallable("update_products_solo", {timeout: 540000})({makat: makats[i]})
                    if(op){
                        slide_pop_successs(`מקט ${makats[i]} עודכן בהצלחה!`)
                    }else{
                        slide_pop_error(`מקט ${makats[i]} לא עודכן!`)
                    }
                }else{
                    slide_pop_error(`מקט ${makats[i]} איננו תקני, ממשיך לשאר המקטים אם קיימים עוד!`)
                }
            }
            loading.value = false;

        }else{
            slide_pop_error("אנא הכנס מקטים תקניים מופרדים בפסיק")
        }
    }
    
    return{
        makats_string, check_makats, loading
    }
}
}
</script>

<style scoped>
.spec-product{
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: auto;
    padding: 5px;
    display: flex;
    flex-direction: column;
}
.title{
    flex-shrink: 0;
    width: 100%;
    font-size: 18px;
    text-align: center;
    height: fit-content;
    margin-bottom: 10px;
}
.input-makats{
    flex-shrink: 0;
    width: 100%;
    font-size: 18px;
    text-align: center;
    height: fit-content;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>