<template>
    <div class="spec-assortments">
        <template v-for="assortment in assortments" :key="assortment.makat">
            <div class="assortment">
                <p style="width:20%; text-align:center;">{{assortment.makat}}</p>
                <p style="width:60%; text-align:center;">{{assortment.name}}</p>
                <el-button type="success" style="width:20%; text-align:center;" :id="assortment.makat" @click="update_assortment(assortment.makat)" v-loading="loading">עדכן</el-button>
            </div>
        </template>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore, projectFunctions } from '../../../firebase/config';
import { onMounted } from '@vue/runtime-core';
import { slide_pop_successs } from '../../../Methods/Msgs';
export default {
setup(){
    const assortments = ref([]);
    const loading = ref(false);
    const get_assortments = async () => {
        const docs = await projectFirestore.collection('Assortments').get()
        if(!docs.empty){
            assortments.value = docs.docs.map(doc => doc.data());
        }
    }

    const update_assortment = async (ass) => {
        loading.value = true;
        await projectFunctions.httpsCallable("update_assortment_solo")({assortment: ass})
        slide_pop_successs("הגיוון עודכן בהצלחה!")
        loading.value = false;
    }

    onMounted(() => {
        get_assortments();
    })
    return{
        assortments, update_assortment, loading
    }
}
}
</script>

<style scoped>
.spec-assortments{
    width: 100%;
    height: calc(100% - 40px);
    display: grid;
    overflow-y: auto;
    grid-template-columns: 100%;
    grid-auto-rows: 60px;
    padding: 5px;
}
.assortment{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
</style>