<template>
    <div class="priority">
        <div class="comp" v-if="show_comp">
            <div class="close-comp">
                <i class="material-icons" style="color:var(--danger); cursor:pointer;" @click="show_comp = false"> close </i>
            </div>
            <div class="comp-title">
                <p>עדכון ספציפי</p>
            </div>
            <SpecAssortment v-if="comp == 'גיוונים'"/>
            <SpecificProduct v-if="comp == 'מוצרים'"/>
            <SpecificClient v-if="comp == 'לקוחות'"/>
        </div>
        <div class="fetching-data" v-if="timer > 0">
            <img src="https://www.globant.com/themes/custom/globant_corp_theme/images/2019/Loading-Globant.gif" alt="">
            <p style="font-size:26px; color:var(--yellow); margin-top: 15px;">{{proccess}}</p>
            <p style="font-size:18px; color:var(--yellow); margin-top: 15px;">טוען נתונים</p>
            <p style="font-size:14px; color:var(--light); margin-top: 15px;">{{timer}} שניות</p>
        </div>
        <div class="stats">
            <template v-for="stat in stats" :key="stat.name">
                <div class="stat">
                    <div class="stat-image">
                        <img :src="stat.icon" alt="">
                    </div>
                    <div class="stat-data">
                        <p style="color:var(--yellow); font-size:24px;">{{stat.name}}</p>
                        <p>{{stat.amount}}</p>
                        <p v-if="stat.updated && !stat.updated.seconds" style="color:var(--purple); font-size:14px;">{{new Date(stat.updated).toLocaleString('he')}}</p>
                        <p v-else style="color:var(--purple); font-size:14px;">{{new Date(stat.updated.seconds*1000).toLocaleString('he')}}</p>
                    </div>
                    <div class="stat-actions">
                        <el-button type="success" class="stat-btn" @click="update_all(stat)">טען הכל</el-button>
                        <el-button type="primary" class="stat-btn" @click="comp = stat.name, show_comp = true">טען ספציפי</el-button>
                    </div>
                </div>
            </template>
        </div>
        <div class="load-panel">
            
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
import { projectFirestore, projectFunctions } from '../../firebase/config'
import { slide_pop_successs } from '../../Methods/Msgs';
import Swal from 'sweetalert2';
import SpecAssortment from '../../components/Admin/Priority/SpecificAssortment.vue';
import SpecificProduct from '../../components/Admin/Priority/SpecificProduct.vue';
import SpecificClient from '../../components/Admin/Priority/SpecificClient.vue';

export default {
components:{SpecAssortment, SpecificProduct, SpecificClient},
setup(){
    const stats = ref([]);
    const show_comp = ref(false);
    const comp = ref('')

    const get_stats = async () => {
        stats.value = [];
        const docs = await projectFirestore.collection('Utils').get()
        if(!docs.empty){
            stats.value = docs.docs.map(doc => doc.data())
            var inx = stats.value.findIndex(stat => stat.hasOwnProperty("goal_to_do"));
            if(inx != -1){
                stats.value.splice(inx,1);
            }
        }

        console.log(stats.value);
    }

    const timer = ref(0);
    const proccess = ref('')

    const update_all = async (type) => {
        let time_past = (new Date() - type.updated.toDate()) * 0.001 / 60
        if(time_past < 5){
            Swal.fire({
                icon:'info',
                title: 'זמן קירור',
                text: 'אנא המתן לפחות 5 דק בין טעינות מלאות.'
            });
            return
        }
        switch (type.name) {
            case 'לקוחות':
                update_clients();
                break;
            case 'מוצרים':
                update_products();
                break;
            case 'גיוונים':
                update_assortments();
                break;
            case 'הנחות':
                update_discounts();
                break;
            case 'סוכנים':
                update_agents();
                break;
            case 'מחירונים':
                update_price_lists();
                break;
            default:
                break;
        }
    }

    const update_clients = async () => {
        proccess.value = 'לקוחות'
        timer.value = 0;
        var timer_counter = setInterval(() => {
            timer.value += 1;
        }, 1000);

        await projectFunctions.httpsCallable('update_clients', {timeout: 540000})().then(res => {
            console.log(res.data);
            get_stats();
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'שגיאה',
                text: err + 'שלח לאיבגי את השגיאה הזו בבקשה.'
            })
        })
        clearInterval(timer_counter)
        timer.value = 0;
        slide_pop_successs('לקוחות עודכנו בהצלחה!')
    }

    const update_products = async () => {
        proccess.value = 'פריטים'
        timer.value = 0;
        var timer_counter = setInterval(() => {
            timer.value += 1;
        }, 1000);

        await projectFunctions.httpsCallable('update_products', {timeout: 540000})().then(res => {
            console.log(res.data);
            get_stats();
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'שגיאה',
                text: err + ' שלח לאיבגי את השגיאה הזו בבקשה. '
            })
        })
        clearInterval(timer_counter)
        timer.value = 0;
        slide_pop_successs('פריטים עודכנו בהצלחה!')
    }

    const update_discounts = async () => {
        proccess.value = 'הנחות'
        timer.value = 0;
        var timer_counter = setInterval(() => {
            timer.value += 1;
        }, 1000);

        await projectFunctions.httpsCallable('update_discounts', {timeout: 540000})().then(res => {
            if(res){
                console.log('Discounts - Done :)');
            }
            get_stats();
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'שגיאה',
                text: err + ' שלח לאיבגי את השגיאה הזו בבקשה. '
            })
        })
        clearInterval(timer_counter)
        timer.value = 0;
        slide_pop_successs('הנחות עודכנו בהצלחה!')
    }

    const update_agents = async () => {
        proccess.value = 'סוכנים'
        timer.value = 0;
        var timer_counter = setInterval(() => {
            timer.value += 1;
        }, 1000);

        await projectFunctions.httpsCallable('update_agents', {timeout: 540000})().then(res => {
            if(res){
                console.log('Agents - Done :)');
            }
            get_stats();
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'שגיאה',
                text: err + ' שלח לאיבגי את השגיאה הזו בבקשה. '
            })
        })
        clearInterval(timer_counter)
        timer.value = 0;
        slide_pop_successs('סוכנים עודכנו בהצלחה!')
    }

    const update_price_lists = async () => {
        proccess.value = 'מחירונים'
        timer.value = 0;
        var timer_counter = setInterval(() => {
            timer.value += 1;
        }, 1000);

        await projectFunctions.httpsCallable('update_price_lists', {timeout: 540000})().then(res => {
            if(res){
                console.log('Price Lists - Done :)');
            }
            get_stats();
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'שגיאה',
                text: err + ' שלח לאיבגי את השגיאה הזו בבקשה. '
            })
        })
        clearInterval(timer_counter)
        timer.value = 0;
        slide_pop_successs('מחירונים עודכנו בהצלחה!')
    }
    
    const update_assortments = async () => {
        proccess.value = 'גיוונים'
        timer.value = 0;
        var timer_counter = setInterval(() => {
            timer.value += 1;
        }, 1000);

        await projectFunctions.httpsCallable('update_assortments', {timeout: 540000})().then(res => {
            if(res){
                console.log('Assortments- Done :)');
            }
            get_stats();
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'שגיאה',
                text: err + ' שלח לאיבגי את השגיאה הזו בבקשה. '
            })
        })
        clearInterval(timer_counter)
        timer.value = 0;
        slide_pop_successs('גיוונים עודכנו בהצלחה!')
    }

    onMounted(() => {
        get_stats();
    })

    return{
        stats, update_clients, timer, proccess, update_products, update_all,show_comp,
        comp, 
    }
}
}
</script>

<style scoped>
.priority{
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.comp{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 95%;
    width: 375px;
    background: var(--light);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.664);
    overflow: hidden;
}
.comp-title{
    width: 100%;
    height: 40px;
    background: var(--success);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.712);
}
.close-comp{
    position: absolute;
    top: 5px;
    left: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.stats{
    width: 100%;
    max-width: 500px;
    display: grid;
    height: fit-content;
    grid-auto-rows: fit-content;
    grid-template-columns: 100%;
    grid-auto-flow: row;
    gap: 10px;
    margin: auto;
}
.stat{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--secondary);
    border-radius: 5px;
    box-shadow: 0 0 3px 1px rgb(10 10 10 / 20%);
}
.stat-image{
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.stat-image img{
    max-width: 80%;
    max-height: 80%;
}
.stat-data{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.stat-actions{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5px;
}
.stat-data p{
    font-size: 18px;
    color: var(--light);
    margin-bottom: 10px;
}
.load-panel{
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
}
.fetching-data{
    position: absolute;
    width: 100%;
    max-width: 375px;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--secondary);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.363);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.fetching-data img{
    border-radius: 50%;
    max-height: 40%;
    max-width: 40%;
    background: var(--light);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.377);
}
.stat-btn{
    width: 100%;
    height: 32%;
}
@media only screen and (max-width: 600px) {
    .stats{
        width: 100%;
        height: fit-content;
        grid-auto-rows: fit-content;
        grid-template-columns: 100%;
        grid-auto-flow: row;
    }
}
</style>